import { Col, Container, Row } from "react-bootstrap"
import { Layout, SEO } from "../components"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { I18n } from "react-i18next"
import React from "react"
import RichTextRenderer from "../components/richTextRenderer"
import { graphql } from "gatsby"

const NewsPage = ({ data }) => {
  const { title, cover, body, slug } = data.contentfulNews
  return (
    <I18n>
      {t => (
        <Layout>
          <SEO title={title} />
          <div className="section bg-dark-blue mt-4">
            <Container className="height-md-30">
              <Row>
                <Col>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                      <li className="breadcrumb-item">
                        <Link to="/" className="text-white">
                          {t("home")}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/news" className="text-white">
                          {t("news")}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={`/post/${slug}`} className="text-white">
                          {title}
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content">
            <div className="section">
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" md="8">
                    {cover && <img src={cover.file.url} alt={title} />}
                  </Col>
                  <Col sm="12">
                    <h2>{title}</h2>
                  </Col>
                  <Col sm="12">
                    <div>{RichTextRenderer(body.json)}</div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Layout>
      )}
    </I18n>
  )
}

export default withI18next()(NewsPage)

export const query = graphql`
  query NewsDetailQuery($slug: String, $lng: String) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    contentfulNews(slug: { eq: $slug }, node_locale: { eq: $lng }) {
      title
      slug
      publishedAt
      body {
        json
      }
      cover {
        file {
          url
        }
      }
    }
  }
`
